#aq-weather-icon{
    width: 70px;
    height: auto;
}
.air-quality {
    margin-top: 80px;
    margin-bottom: 80px;
}

#aq-title {
    font-family: Overpass;
    font-size: 3rem;
    text-transform: uppercase;
}

.table-header{
    text-align: center;
    font-weight: bold;
}

.colorfont3{
    color: white
}
.textsize2{
    font-size: 20px;
}


.good{
    background-color: lightgreen;
}

.moderate{
    background-color: yellow;
}

.usg{
    background-color: orange;
}

.unhealthy{
    background-color: red;
}

.vunhealthy{
    background-color: purple;
}

.hazard{
    background-color: maroon;
}
.weather-search-results{

    padding-left: 100px;
    margin-left: 30px;
    border-radius: 25px;
    padding-top: 30px;
    width:800px;
    height: 450px;
    background-color: white;
    font-size: small;
}
.gage{

    width: 300px;
    height: 450px;
    display: block;

    padding-left: 40px;
    padding-top: 30px;
    border-radius: 25px;
    background-color: white;
}

.searchBox {
  background-color: white;
}
