.mapEl {
  height: 75vh;
  width: 85vw;
  border-style:solid;
  border-width: 8px;
}
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline:10vh
}
.forecastMap{
  height: 120vh;
  width: 30vw;
  border-style:solid;
  border-width: 8px;
}

.center1{
  display:contents;
  justify-content: center;
  padding: 5%;
}
.colortext{
  color: white;
}
.centered{
  display:flex;
  justify-content: center;
  /* padding-top: 5%; */
}

.background2{
  color: black;
}
.size{
  padding-top: 3%;
 height: 70vh;
 width: 10vw;
 size:10rem;
}
.border2{
  border-style:solid;
  border-width: 8px;
}
.size2{
 height: 100vh;
 width: 20vw;
}
