.dashboard {
  margin: 0;
  padding-top: 2%;
  padding-bottom: 5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
}



.background {
  background-color: whitesmoke;
}

.title {
  font-weight: bold;
  font-size: larger;
}

.textSize {
  font-size: 22px;
  font-weight: 200;
}

.upperNav {
  background-color: #005985;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar-dark .navbar-brand {

  padding-left: 40px;

}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  margin-right: 12px;
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
}

.linkNav {
  padding-right: 232px;
}

.lowerNav {
  background-color: #1a3157;
  color: #6d81a1;
  text-align: center;
  font-weight: bold;
  min-height: 3vw;
  display: inline-block;
  padding: 10px;
  margin: auto;
  width: 100%;
}

.lowerNav .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
  width: 70%;
  max-width: 400px;
  padding-left: 1%;
  padding-right: 1%;
}

.searchBox {
  background-color: transparent;
}


.searchForm {
  margin: 0px;
  text-align: center;
  border-radius: 25px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}


.center2 {
  display: flex;
  justify-content: center;
  width: 120%;
  border-radius: 20px;
}

.textboxSearch {
  opacity: 0.5;
  color: white !important;
}


.loader {
  width: 10em;
  height: 10em;
}

.weatherCol {
  padding: 10px;
  flex: 1;
}

.firstweatherContainer {
  border: none;
  border-radius: 25%;
  background-color: #a9c9eb;
}

.myDropdown {
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.dropdownitem {

  color: white;
  padding: 10px;
  margin: 0px;
  font-size: 15px;


}


.weatherContainer {
  border: none;
  border-radius: 25%;
  background-color: #a9c9eb;
}

.weatherRow {
  padding: 10px;
  display: flex;
}

.cards {
  width: 90%;
  max-width: 2000px;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 2%;
  margin-top: 2%;
}

.iframe_rss {
  min-height: 350px;
}

.cardBodyRSS {
  color: black;
  display: flex;
  flex-direction: column;
  padding-right: auto;
  padding-left: auto;
  text-align: center;
  min-height: 350px;

}


.cardBody {
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  padding-right: auto;
  padding-left: auto;
  text-align: center;

}

.cardColLeft {
  padding: 2%;
  margin: 5%;
  margin-top: 0%;
  height: 100%;
}

.cardCol {
  padding: 2%;
  margin: 5%;
}

.cardButton {
  color: white;
  background-color: #191975;
  font-size: 1em;
  border: 2px solid #191975;
  margin-top: auto;
  min-width: 10em;
  width: 60%;
}

.card {
  border: none;
  height: 100%;
  margin-top: 30px;
  background-color: white;
}


.card-weather {
  border: none;
  padding-bottom: 5em;
  min-height: 400px;
  margin-top: 30px;
  max-height: 500px;

}

.cardTitle {
  font-size: 20px;
  color: black;
  font-weight: bold;
}

.cardContent {
  display: flex;
}

.loc_text {
  font-size: 18px;
}

.zipSearch {
  font-size: 24px;
}

.gauge {
  height: 340px;
  width: 230px;
}